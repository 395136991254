import  { Component } from "react";

class CheckBoxTerminos extends Component {
  state = {
    checked: false,
  };
 
  render() {
    return (
      <>
        <input
          className="checkbox"
          type="checkbox"
          value={this.state.checked}
          onClick={() => {
            this.setState((prevState) => ({
              checked: !prevState.checked,

            }));
            
            this.props.disableButton(this.props.value, !this.state.checked);
            
          }}
        />{" "}
        
      </>
    );
  }
}

export default CheckBoxTerminos;