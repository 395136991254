import '../styles/terminos.css'
import {useHistory} from 'react-router-dom';
export const Terminos = () => {
    const history = useHistory();
    function goBack(){
        history.goBack();
      }
    return (
        <>
        <div>
        <div className="container">
            <div className="titulo">
                <h2>Condiciones de Uso que rigen el uso del sitio Web para pago de trámites en línea del Colegio de
                    Médicos y Cirujanos de Costa Rica (CMC)</h2>
            </div>
            <div className="bodyTerminos" >
                <p>Al usar el Sitio para el pago de trámites y servicios del Colegio de Médicos y Cirujanos de Costa Rica
                (CMC), usted manifiesta su conformidad con los términos y condiciones de esta página electrónica, así
                como de todas las leyes y reglamentos aplicables de conformidad a la legislación vigente y aplicable
                para el uso del Sitio.</p>

                <p>El CMC, se reserva el derecho a cambiar estas Condiciones en cualquier momento, las cuales aplicarán
                inmediatamente después de que hayan sido publicadas en el Sitio.</p>

                <h4><strong>Objetivo del Sitio</strong></h4>

                <p>A través de esta página web el CMC facilita a los usuarios la consulta de datos de su colegiatura,
                cursos, certificaciones y demás servicios que ofrece. En atención a lo anterior, se pone a disposición
                del Usuario la facilidad de pagar por medio de tarjeta de dédito o crédito de las marcas VISA o MASTER
                CARD los montos establecidos para estos servicios.</p>

                <h4><strong>Uso Permitido</strong></h4>

                <p>En este acto manifiesto que conozco que únicamente estoy autorizado a visitar, ver y consultar datos
                para mi propio uso, y que no deberé duplicar, descargar, publicar, modificar o distribuir o alterar el
                contenido de página electrónica, o del material contenido en este Sitio para ningún propósito distinto
                que no sea el pago de servicios.</p>


                <h4><strong>Derechos de Propiedad Intelectual:</strong></h4>

                <p>Todos los materiales y elementos que aparecen en este sitio web, incluyendo el texto, el nombre, el
                diseño, los logotipos, los gráficos, los iconos y las imágenes son de propiedad única y exclusiva del
                CMC. El software usado en el sitio para la venta de servicios es de propiedad del CMC. El Usuario acepta
                que puede utilizar el contenido de este sitio solamente con el fin de obtener información y pagar por
                colegiaturas o servicios del CMC . Ningún material de este sitio se puede copiar, reproducir, modificar,
                republicar, transmitir, o distribuir en cualquier forma o por cualquier medio sin un permiso escrito.
                Todos los derechos no expresados concedidos en el contenido del sitio son reservados.</p>

                <h4><strong>El Usuario entiende y acepta expresamente lo siguiente:</strong></h4>
                    <ul>
                <li><p>Que el CMC no se obliga a que el sitio se encuentre libre de errores y que no tendrá interrupciones.</p></li>
                <li><p>Que el CMC no puede asegurar que los archivos que descargue del sitio estarán libres de virus o de
                contaminación o de características destructivas, bajo el entendido de que el CMC siempre tomará las
                acciones para evitar estos inconvenientes.</p></li>
                <li><p>Que el CMC no será responsable por ningún daño de cualquier tipo que surja del uso de este sitio,
                incluyendo sin limitación, daños directos, indirectos, incidentales, punitivos y consecuenciales.</p></li>
                <li><p>Que el CMC no puede hacerse responsable del contenido y las políticas de los sitios Web de terceras
                organizaciones que el Usuario pueda alcanzar desde enlaces alojados en el sitio del CMC. Es total
                responsabilidad del Usuario conocer la tecnología que está utilizando y su navegador para tomar sus
                propias decisiones de las acciones que desea realizar.</p></li>
                <li><p>Que el CMC no se hace responsable de los daños y perjuicios que se produzcan por fallos o malas
                configuraciones del software instalado en el ordenador del Usuario, fallos en la transmisión y defectos
                del fabricante de software.</p></li>
                <li><p>Que el Usuario es el único responsable de lo que suceda en su propio Equipo, y deberá tomar las medidas
                de seguridad adecuadas para proteger la información alojada en el mismo, para evitar pérdidas o daños
                causados por descargas desde el sitio del CMC.</p></li>
                <li><p>Que libera de cualquier responsabilidad e indemnización al CMC y a cada uno de los funcionarios por los
                actos realizados por terceros, o que surjan del uso de este Sitio, incluyendo también su uso del Sitio
                para proporcionar un enlace a otro Sitio o para cargar contenido o cualquier otra información al Sitio.</p></li>
                <li><p>Que el CMC se reserva el derecho a suspender temporalmente la prestación del servicio sin previo aviso
                al Usuario, siempre y cuando sea necesario para efectuar operaciones de mantenimiento, actualización o
                mejora del servicio. Igualmente podrá modificar las condiciones de acceso y o concreta ubicación del
                contenido integrante del sitio web, así como impedir, restringir, bloquear, suprimir o retirar el acceso
                a los servicios a los Usuarios cuando estos no hicieren un uso licito, honrado y diligente de los
                servicios.</p></li>
                <li><p>Que el CMC asegura que toda información sumistrada por el tarjetahabiente es completamente confidencial</p></li>
            </ul>
                <p>Sin prejuicio de todo lo anteriormente mencionado, el Usuario podrá reportar la mala conducta de otros
                usuarios y/o funcionarios, proveedores de servicios y/o productos a los que se hace referencia, o que
                estén incluidos en el Sitio de <a href="http://pagos.medicos.cr">pagos.medicos.cr</a> con el fin de investigar y tomar las medidas que
                correspondan.</p>

                <h4><strong>Jurisdicción y Lugar</strong></h4>

                <p>Las leyes de la República de Costa Rica reglamentan el uso de las órdenes hechas en el Sitio. El Usuario
                acepta que cualquier demanda legal contra el CMC será gobernada por las leyes de la República de Costa
                Rica.El Usuario acepta que cualquier demanda que se presente por el uso de órdenes hechas en el Sitio
                serán conocidas por los Tribunal de la República de Costa Rica.</p>

                <h4><strong>Servicio y asistencia</strong></h4>

                <p>El CMC manifiesta que se esforzará a para darle el mejor servicio y la mejor ayuda técnica. Asimismo, el
                CMC asegura que su personal de soporte procurará asistir a los clientes a través del correo electrónico
                en los problemas relativos a los productos CMC o por el centro de llamadas 2210-2200 o al correo
                <a href="mailto:informatica@medicos.cr">informatica@medicos.cr</a></p>

            </div>
            <button className="botones btn-consultar btn-terminos" onClick={goBack} id="btn-atras">Atras</button>
        </div>
    </div>
            
        </>
    )
}
