import '../styles/footer.css'

export const Footer = () => {
    return (
        <>
        <footer>
            <div className="wrapper">
                <p id="copy">
                Copyright © 2021
                </p>
                <p id="cole">
                Colegio de Médicos y Cirujanos, Costa Rica
                </p>
            </div>
        </footer>
        </>
    )
}
