import {useState} from 'react';
import '../styles/pagos.css'
import {Link,useHistory} from 'react-router-dom';
import Modal from 'react-modal';
import CheckBoxTerminos from './CheckBoxTerminos';

const customStyles = {
  content : {
    top                   : '0',
    left                  : '0',
    right:'0',
    widht:'100%',
    height:'100%',
    position:'fixed',
    zIndex:'10'
    
  }
};

Modal.setAppElement('#root')
export const PagoCuotas = () => {

    const history = useHistory();
    var numerosLetras = require('numeros_a_letras');
    const nombre = sessionStorage.getItem("nombre")
    const ced = sessionStorage.getItem("cedula")
    const cod = sessionStorage.getItem("codigoProfesional")
    const total = parseInt(sessionStorage.getItem("total")) 
    const clase = JSON.parse(sessionStorage.getItem('cuotas'))
    // let detalle = (sessionStorage.getItem("detalle"))
    // const detallesPago = JSON.parse(detalle);
    let idFacts = sessionStorage.getItem("idFacturas")
    const [disable,setDisable] = useState(true)
    let descripcionCuotas = JSON.parse(sessionStorage.getItem("descripcionCuotas"))

    function goBack(){
        history.goBack();
      }

    const [modalIsOpen,setIsOpen] = useState(false);

    // detalles del modal de pagos 

    function closeModal(){
      setIsOpen(false);
    };
    // var subtitle;

    function makePayment(){
      setIsOpen(true);
    };

    function disableButton(input,checked) {

      if (checked) {
        setDisable(false)
      } else{
        setDisable(true)
      }
      
    }
    // let totalConDecimales = total.toFixed(2)

    const params={
      facturas:'&facturas=',
      codigoProfesional:'&codigoProfesional=',
      clase:'&clase='
    }
    var facturasJuntadas = JSON.parse(idFacts).toString()
    
    // https://6787198.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=191&deploy=1&compid=6787198&h=8e614f38c8aedefc5ddd&descriptionProducts=Colegiaturas&facturas=27864,27863,27862,27819&clase=1&codigoProfesional=MED8120
    let url = 'https://6787198.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=191&deploy=1&compid=6787198&h=8e614f38c8aedefc5ddd&descriptionProducts=Colegiaturas'+params.facturas+facturasJuntadas+params.clase+clase[0].class.id+params.codigoProfesional+cod;

    var modal;
    
    if(modalIsOpen){
      modal = <Modal
      isOpen={modalIsOpen}
    
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Pago en linea"
    >
      <form>
        <iframe title="pasarela de BNCR" src={url} />
      </form>
    </Modal>
    } else {
      modal = <div></div>
    }

    return (
        <>
        <div className="pago">
      <div className="banner">
        <div className="title">
              <h1>Pago</h1>
            </div>
          </div>
        </div>
        <div className="pagoBody">
        <div className="datosColegio">
            <h2>Colegio de Medicos y Cirujanos C.R.</h2>
            <div className="faxTel">
            <p className="tel">Telefono: 2210-2200</p>
            <p className="fax">Fax: 2232-2406</p>
            </div>
            <p className="apartado">Apartado 546-1000 San Jose C.R.</p>
          </div>
            <div className="datosCliente">
            <p>Cliente: {nombre} </p>

            <p>Codigo: {cod} </p>

            <p>Identificacion: {ced} </p>

            <p>Monto en letras: {numerosLetras(total, 'entero', 0, 'CENTIMOS','COLONES')} COLONES </p>
            <table className="tablas">
                <thead>
                <tr>
                    <th>Descripcion</th>
                    <th>Monto</th>
                </tr>
                </thead>
                <tbody>
                    {
                    descripcionCuotas.map(cuota=>{
                      return <tr key={cuota.descripcion}>
                      {cuota.descripcion ==="" ? ""  : <td>{cuota.descripcion}</td>}
                      {
                         cuota.total ===0 ? "": <td>{parseInt(cuota.total).toLocaleString(
                        undefined,
                        {minimumFractionDigits: 2}
                      )}
                      </td>
                      }
                      </tr>
                      })
                    }

                </tbody>
            </table>
            <div className="totalCuotas">
            <p>El total a cargar a la tarjeta es: {
                      
                      total.toLocaleString(
                        undefined,
                        {minimumFractionDigits: 2}
                      )
                      }</p>
            
            </div>
            
            {modal}
            </div>
            <div className="terminos">
            <p>He leido y acepto los <Link  to="/terminos">terminos y condiciones</Link ><CheckBoxTerminos disableButton={disableButton}  /></p>
            </div>
            <div className="botonera">
              <button className="botones btn-consultar" onClick={goBack} id="btn-atras">Atras</button>
          
              <button className="botones btn-sig" disabled={disable} onClick={makePayment} id="btn-pro">Procesar</button>
            </div>
          </div>
            
        </>
    )
}
