import { Nav } from './Nav';

export const Header = () => {
    return (
        <>
        <div className="header">
            <Nav />
            <div className="header-container">
                <a href="http://www.medicos.cr"><img className="logo-image" src="http://www.medicos.cr/website/images/logo-blanco-.png" alt="logo blanco"></img></a>
            </div>
            
        </div>
            
        </>
    )
}
