import {FormProfesional} from '../components/FormProfesional'
import '../styles/form-profesional.css'

export const BannerPrincipal = () => {
    return (
        <>
            <div className="banner-principal">
                <div className="texto-banner">
                    <h1 >Pagos en linea</h1>
                    <p>El Colegio de Médicos y Cirujanos pone a su disposición un sistema altamente seguro para el pago de sus colegiaturas y el pago de cursos, 
                    talleres y congresos, todo esto mediante sus tarjetas y con el respaldo del Banco  Nacional de Costa Rica.  </p>
                </div>
            </div>
            <div>
                <FormProfesional />
            </div>
        </>
    )
}
