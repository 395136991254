import {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import Modal from 'react-modal';
import '../styles/pagos.css'
import CheckBoxTerminos from './CheckBoxTerminos';

const customStyles = {
  content : {
    top: '0',
    left: '0',
    width:'100%',
    height:'100%',
    position:'fixed',
    zIndex:'10'
  }
};

Modal.setAppElement('#root')

export const Pago = () => {
const history = useHistory();
var numerosLetras = require('numeros_a_letras');
const nombre = sessionStorage.getItem("nombre");
const ced = sessionStorage.getItem("cedula");
const cod = sessionStorage.getItem("codigoProfesional");
const total = parseInt(sessionStorage.getItem("total")); 

const [disable,setDisable] = useState(true)

function goBack(){
  history.goBack();
}

let descripcion;
let detalle = (sessionStorage.getItem("detalle"))

if(detalle.facturas === undefined){
  descripcion = "Adelanto de colegiatura"
}

const [modalIsOpen,setIsOpen] = useState(false);


function closeModal(){
  setIsOpen(false);
};
const detallesPago = JSON.parse(detalle);
function makePayment(){
  setIsOpen(true);
};
const params={
  purchaseAmount:'&purchaseAmount=',
  codigoProfesional:'&codigoProfesional=',
  clase:'&clase='
}
let totalConDecimales = total.toFixed(2)

let url = 'https://6787198.extforms.netsuite.com/app/site/hosting/scriptlet.nl?deploy=1&descriptionProducts=Colegiaturas'+params.purchaseAmount+totalConDecimales+params.codigoProfesional+cod+params.clase+detallesPago.class+'&script=191&compid=6787198&h=8e614f38c8aedefc5ddd';

var modal;

if(modalIsOpen){
  modal = <Modal
  isOpen={modalIsOpen}
  onRequestClose={closeModal}
  style={customStyles}
  contentLabel="Pago en linea"
>

    <iframe title="pasarela de BNCR" src={url} />

</Modal>
} else {
  modal = <div></div>
}

function disableButton(input,checked) {

  if (checked) {
    setDisable(false)
  } else{
    setDisable(true)
  }
  
}
    return (

        <>
    <div className="pago">
      <div className="banner">
        <div className="title">
              <h1>Pago</h1>
            </div>
          </div>
        </div>
        <div className="pagoBody">
        {modal}
          <div className="datosColegio">
            <h2>Colegio de Médicos y Cirujanos C.R.</h2>
            <div className="faxTel">
            <p className="tel">Teléfono: 2210-2200</p>
            <p className="fax">Fax: 2232-2406</p>
            </div>
            <p className="apartado">Apartado 546-1000 San Jose C.R.</p>
          </div>
            <div className="datosCliente">
            <p>Cliente: {nombre} </p>

            <p>Código: {cod} </p>

            <p>Identificación: {ced} </p>

            <p>Monto en letras: {numerosLetras(total, 'entero', 0, 'CENTIMOS','COLONES')} COLONES </p>
            <table className="tablas">
                <thead>
                <tr>
                    <th>Descripción</th>
                    <th>Monto a pagar</th>
                </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{descripcion}</td>
                    <td>{
                    parseInt(detallesPago.item_total).toLocaleString(
                      undefined,
                      {minimumIntegerDigits:2}
                    )}</td>
                    
                  </tr>
                </tbody>
            </table>
            <div className="terminos">
            <p>He leido y acepto los <Link to="/terminos">terminos y condiciones</Link ><CheckBoxTerminos disableButton={disableButton}  /></p>
            </div>
            </div>
            
            <div className="botonera">
            <button className="botones btn-consultar" onClick={goBack} id="btn-atras">Atras</button>
        
          <button className="botones btn-sig" disabled={disable} onClick={makePayment} id="btn-pro">Procesar</button>
          </div>
          </div>
        </>
    )
}
