
import {Header} from './components/Header'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Cuotas from './components/Cuotas';
import {Pago} from './components/Pago';
import './styles/pagos.css'
import './styles/nav.css'
import './styles/header.css'
import './styles/banner-principal.css'

import { BannerPrincipal } from './components/BannerPrincipal';
import { Confirmacion } from './components/Confirmacion';
import { PagoCuotas } from './components/PagoCuotas';
import {Footer} from './components/Footer'
import { Terminos } from './components/Terminos';


// este sera el metodo principal para renderizar todo el front de la parte de pagos en linea
// basename={window.location.pathname || ''}
function App() {
  return (
    <>
      <BrowserRouter basename={window.location.pathname || ''}>
      <Header />
          <Switch>
            <Route path="/cuotas" component={Cuotas} />
            <Route path="/pago" component={Pago} />
            <Route path="/confirmacion" component={Confirmacion} />
            <Route path="/pagoCuotas" component={PagoCuotas}/>
            <Route path="/terminos" component={Terminos}/>
            <Route path="/" exact component={BannerPrincipal} className="pagos"/>
          </Switch>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
