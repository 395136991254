
import '../styles/cuotas.css'
import 'react-dropdown/style.css';
import { Component } from "react";
import Checkbox from "./CheckBox";
import Dropdown from 'react-dropdown';
import $ from 'jquery';

const options = [
  0,1,2,3,4,5,6,7,8,9,10,11,12
]
const defaultOption = options[0]

class Cuotas extends Component {
  componentDidMount(){
    this.precios()
    //deshabilita todos menos el primer check
    $(':checkbox:not(:first)').prop('disabled', true);

$('input[type="checkbox"]').on('click', function(event) {
        if ($(this).prop('checked') === true) {
            $(this).closest('tr').prev().find(':checkbox').prop('disabled', true);
            $(this).closest('tr').next().find(':checkbox').prop('disabled', false);
    } else {
      $(this).closest('tr').prev().find(':checkbox').prop('disabled', false);
      $(this).closest('tr').next().find(':checkbox').prop('disabled', true);
    }
});
  }

  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      facturas: "",
      facturasMarcadas:[],
      descripcionFacturas:[{
        descripcion:'',
        total:0
      }],
      tarifa:0,
      deshabilitado:true,
      clase:0
    };
    this.goBack = this.goBack.bind(this);
    this.payPage = this.payPage.bind(this);
    this.payCuotasPage = this.payCuotasPage.bind(this)
    this.calculateTotal = this.calculateTotal.bind(this);
    this.precios = this.precios.bind(this);
    this.actualizaAdelanto = this.actualizaAdelanto.bind(this);
    // this.validaAnterior = this.validaAnterior.bind(this)
  };
  
  calculateTotal(input, checked, id,placeholder,value) {
    let { total } = this.state;
    let { facturas } = this.state;
    let {facturasMarcadas} = this.state;
    let {descripcionFacturas} = this.state;

    if (checked) {
      total += input;
      facturas += id;
      facturasMarcadas.push(id)
      descripcionFacturas.push({
        descripcion:placeholder,
        total:input
      })

    } else {
      total -= input;
      facturas -= id;
      facturasMarcadas.pop()
      descripcionFacturas.pop()

    }
    this.setState({
      total,
      facturas,
      facturasMarcadas,
      descripcionFacturas
    });
  }
  goBack(){
    this.props.history.goBack()
  }
  payPage(){
    
    let {total} = this.state;
    sessionStorage.setItem("total",total)
    this.props.history.push("pago")
    let detalle = {
      facturas:[],
      total:""
    }
    detalle.total = total
  }

  payCuotasPage(){
    let {facturasMarcadas} = this.state;
    let {total} = this.state;
    let {descripcionFacturas} = this.state;

    sessionStorage.setItem("total",total)
    sessionStorage.setItem("idFacturas",JSON.stringify(facturasMarcadas))
    sessionStorage.setItem("descripcionCuotas",JSON.stringify(descripcionFacturas))

    let detalle = {
      facturas:[],
      total:""
    }
    detalle.facturas.push(facturasMarcadas)
    detalle.total = total
    sessionStorage.setItem("detalle",JSON.stringify(detalle))
    this.props.history.push("pagoCuotas")
  }
  clase(){
    let {clase} = this.state;
    var tipoPro = sessionStorage.getItem("codigoProfesional").slice(0,3);
    switch(tipoPro){
      case "MED":
        return clase = 1
      case "PAF":
        return  clase = 3
      case "TEC":
        return clase = 2
        default:
          clase = 1
    }
    this.setState({
      clase
    })
  }
  precios(){
    let {tarifa} = this.state;

    var tipoPro = sessionStorage.getItem("codigoProfesional").slice(0,3);
    var clase = 0
    // crear un endpoint que traiga estos datos automaticamente 
    var url = 'https://6787198.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=223&deploy=1&compid=6787198&h=5d76ca2c814368ba1792'
    var params = {
      clase : '&clase='
    }

    switch(tipoPro){
      case "MED":
        clase = 1
        var med = url+params.clase+clase
        fetch(med)
        .then(res => res.json())
        .then(res => {
          tarifa = res
          this.setState({
            tarifa
          })
        });
        break;
      case "PAF":
        clase = 3
        var paf = url+params.clase+clase
        fetch(paf)
        .then(res => res.json())
        .then(res => {
          tarifa = res
          this.setState({
            tarifa
          })
        });
        break;
      case "TEC":
        clase = 2
        var tec = url+params.clase+clase
        fetch(tec)
        .then(res => res.json())
        .then(res => {
          tarifa = res
          this.setState({
            tarifa
          })
        });
        break;
        default:
          tarifa = 0
    }
  }
  guardaDescripcion(placeholder){
    let {descripcionFacturas} = this.state;


    this.setState({
      descripcionFacturas
    })
  }
  actualizaAdelanto(input){
    let {total} = this.state;
    let clase = this.clase();
    let {tarifa} = this.state;
    let mult = input.value;
    total =  tarifa * mult;
    let date = new Date()
    let trandate;
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()

    if(month < 10){
      trandate = `${day}/0${month}/${year}`
    }else{
      trandate = `${day}/${month}/${year}`
    }

    this.setState({
      total
    });
    sessionStorage.setItem("total",total)
    let detalle = {
      "trandate":trandate,
      "item_total":"",
      "codigoProfesional":"",
      "class":clase
    }
    let codigoPro = sessionStorage.getItem("codigoProfesional")
    detalle.item_total = total
    detalle.codigoProfesional = codigoPro
    sessionStorage.setItem("detalle",JSON.stringify(detalle))
  }

  
  render() {

    
    const nombre = window.sessionStorage.getItem("nombre")
  
  
    var cuotas = JSON.parse(sessionStorage.getItem("cuotas"))
    let body;

    if(cuotas.length>0){
       body = <div>
              <div className="nombreUsuario">
                <h2>{nombre}</h2>
                <p>Marque las cuotas que desea pagar</p>
                <p>Debe seleccionar la cuota más antigua para que se habilite la siguiente</p>
              </div>
            <table className="tablas">
                  <thead>
                  <tr>
                      <th>Documento</th>
                      <th>Descripción</th>
                      <th>Periodo</th>
                      <th>Vencido</th>
                      <th>Monto</th>
                      <th>Seleccionar </th>
                  </tr>
                  </thead>
                  <tbody>
                      {
                        cuotas.map(cuota => {
                        return <tr key={cuota.id}>
                            <td>{cuota.tranid} </td>
                            <td>{cuota.class.text} </td>
                            <td>{cuota.postingperiod}</td>
                            <td>{cuota.duedate}</td>
                            <td>{parseInt(cuota.amount).toLocaleString(
                              undefined,
                            {minimumSignificantDigits: 2}
                            )}</td>
                            <td className="checkbox"><Checkbox onChange={this.validaAnterior} calculateTotal={this.calculateTotal} value={cuota.amount} id={cuota.id} placeholder={cuota.tranid + " " +cuota.postingperiod + " - " + cuota.duedate} /></td>
                          </tr>
                        })
                      }
                  </tbody>
              </table>
                  <p id="subTotal">Total: {
                    parseInt(this.state.total).toLocaleString(
                      undefined,
                      {minimumSignificantDigits:2}
                    )
                  }</p>
                  <div className="buttons">
                  <button className="botones btn-consultar" onClick={this.goBack} id="btn-consultar">Atras</button>
                  <button className="botones btn-sig" onClick={this.payCuotasPage} id="btn-sig">Siguiente</button>
                  </div>
              </div>
    } else {
      
        body = <div>
            <div className="nombreUsuario">
            <h2>{nombre}</h2>
            </div>
            <div className="adelanto">
                    <p>Indique cuantos adelantos quiere hacer: </p>
              <table className="tablas">
                  <thead>
                  <tr>
                      <th>Descripción</th>
                      <th>Monto</th>
                  </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>
                        Adelanto de colegiatura
                        </td>
                        <td>
                        Monto: {parseInt(this.state.tarifa).toLocaleString(
                          undefined,
                          {minimumSignificantDigits:2}
                        )}
                        </td>
                      </tr>
                      
                  </tbody>
              </table>
              {/* eslint-disable-next-line */}
              <div> <Dropdown className="dropdownAdelantos" options={options} onChange={this._onSelect,this.actualizaAdelanto} value={defaultOption} placeholder="0"/></div>
              
                    <p id="subTotal">Total: {parseInt(this.state.total).toLocaleString(
                      undefined,
                      {minimumSignificantDigits:2}
                    )}</p>
            </div>
            <div className="buttons">
              <button className="botones btn-consultar" onClick={this.goBack} id="btn-consultar">Atras</button>
              <button className="botones btn-sig" onClick={this.payPage} id="btn-sig">Siguiente</button>
            </div>
            </div>
    }

      return (
        <>

        <div className="cuotas">
          <div className="banner">
            <div className="title">
              <h1>Cuotas</h1>
            </div>
          </div>
        </div>
        <div className="cuotasBody">
          {body}
          </div>

      </>
    );
  }
}

export default Cuotas;