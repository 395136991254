import React from 'react'
import {useHistory} from 'react-router-dom';

import '../styles/confirmacion.css'

export const Confirmacion = () => {

    const history = useHistory();
    const nombre = sessionStorage.getItem("nombre");
    const ced = sessionStorage.getItem("cedula");
    const cod = sessionStorage.getItem("codigoProfesional");
    const mensaje = sessionStorage.getItem("mensajeConfirmacion");
    function regresaAInicio(){
        history.push("/")
    }
    return (
        <>
            <div className="pago">
      <div className="banner">
        <div className="title">
              <h1>Confirmacion</h1>
            </div>
          </div>
        </div>
        <div className="confirmacionBody">

            <div className="datosCliente">
            <p>Cliente: {nombre} </p>

            <p>Codigo: {cod} </p>

            <p>Identificacion: {ced} </p>

            <p>Se ha realizado correctamente el pago. </p> 
            <p>{mensaje}</p>
            </div>
            <div className="botonera">
            <button className="botones btn-inicio " onClick={regresaAInicio}  id="btn-atras">Inicio</button>
            </div>
        </div>
        </>
    )
}
