import React, { Component } from "react";

class CheckBox extends Component {
  state = {
    checked: false,
    id:[],
    placeholder:[]
  };
 
  render() {
    return (
      <div>
        <input
          className="checkbox"
          type="checkbox"
          value={this.state.checked}
          id={this.state.id}
          placeholder={this.state.name}
          onClick={() => {
            this.setState((prevState,placeholder) => ({
              checked: !prevState.checked,
              placeholder: placeholder
            }));
            
            this.props.calculateTotal(this.props.value, !this.state.checked, this.props.id,this.props.placeholder);
            
          }}
        />{" "}
        
      </div>
    );
  }
}

export default CheckBox;