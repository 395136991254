import { useState } from 'react';
import Select, { ValueType } from 'react-select';
import {useHistory} from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader'


type OptionType = {
    value: string;
    label: string;
  };

const options: OptionType[] = [
  { value: 'MED', label: 'Médico' },
  { value: 'TEC', label: 'Tecnólogo'},
  { value: 'PAF', label: 'Profesional a Fin'},
  { value: 'otro', label: 'Otros'}
]
const pay_options: OptionType[] = [
    { value: "1", label: 'Colegiatura' },
    { value: "2", label: 'Póliza del INS' }
    // { value: 'reservaciones', label: 'Reservaciones' },
    // { value: 'otros_cobros', label: 'Otros Cobros' }
]

export const FormProfesional = () => {
    const history = useHistory();
    const [tipoPago,setTipoPago] = useState<ValueType<OptionType,false>>();
    const listaTipoPago = (option: ValueType<OptionType,false>) => {
      setBotonActivo(false)
    };
    const [loading,setLoading] = useState(false);
    const [ced,setCed] = useState('')
    const [profesional,setProfesional] = useState<ValueType<OptionType,false>>();
    const [cod,setCod] = useState('')
    const [nombre,setNombre] = useState('')
    const listaProfesional = (option: ValueType<OptionType,false>) => {
        setProfesional(option);
      };
    const [borde,setBorde] = useState('')
    const codigoProfesional = profesional?.value+cod
    const [alerta,setAlerta]= useState('')
    const [mensaje,setMensaje] = useState('')
    const [botonActivo, setBotonActivo] = useState(true)
    const [cliente,setCliente] = useState({})
    const paramMed = '&codigoMedico=';
    
  function datosMedico() {
    setLoading(true)
    setBorde('')
    setNombre("");
    if(validateInput()){
      fetch('https://6787198.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=182&deploy=1&compid=6787198&h=887c4bb1f12a8f62601c'+paramMed+codigoProfesional)
      .then(res => {
        return res.json();
      }).then(res =>{
        if(res.Estado==="INACTIVO" || res.Estatus ==="INACTIVO" ){
          setMensaje("El profesional se encuentra inactivo o no se encuentra colegiado");
          setAlerta('inactivo');
          setCliente(JSON.stringify(res));
          setNombre(res.Nombre);
          setCed(res.Cedula)
          setLoading(false)
          
        } else {
          setCliente(JSON.stringify(res));
          setCed(res.Cedula)
          setNombre(res.Nombre)
          setAlerta('');
          setMensaje('');
          setLoading(false)
          if(res.Codigo === undefined){
            setMensaje("Este codigo no devuelve ningun profesional registrado");
              setAlerta('inactivo');
              setNombre("");
              setLoading(false)
           }
           if(res.Estatus === "FALLECIDO"){
             setMensaje("El codigo devuelve un agremiado fallecido")
             setAlerta('inactivo');
             setLoading(false)
           }
        } 
      })
      .catch(err=>{
        console.log(err)
      });
    }
    
    }
    function validateInput(){
      var valido = true
      if(cod===""){
        setMensaje("El campo de codigo debe de tener numeros");
        setAlerta('inactivo')
        setLoading(false)
        setBorde("bordeRojo")
        valido = false
      }
      return valido;
    }

    function consultarPagos(){
      if(validateInput()){

      
      var url = 'https://6787198.extforms.netsuite.com/app/site/hosting/scriptlet.nl?deploy=1'
      var data = {}
      window.sessionStorage.setItem("nombre",nombre)
      window.sessionStorage.setItem("codigoProfesional",codigoProfesional)
      window.sessionStorage.setItem("cedula",ced)
      
      var paramTipo = "&tipo=";
      var paramMed = "&codigoProfesional=";

      switch(tipoPago?.value){
        case "1":
          url = url+paramMed+codigoProfesional+paramTipo+tipoPago.value + "&script=189&compid=6787198&h=95fc292bb5e27ce2b2d1";
          fetch(url)
          .then(res => res.json())
          .then(res =>{
            data= res;
            //pensaria hacer un objeto con la data que viene aca
            sessionStorage.removeItem('dataCliente');
            sessionStorage.setItem('dataCliente',cliente);
            sessionStorage.setItem("cuotas",JSON.stringify(data));
            history.push("Cuotas")
            return data;
          });
          break;
          case "2":
            url = url+paramMed+codigoProfesional+paramTipo+tipoPago.value + "&script=189&compid=6787198&h=95fc292bb5e27ce2b2d1";
          fetch(url)
          .then(res => res.json())
          .then(res =>{
            data= res;
            //pensaria hacer un objeto con la data que viene aca
            sessionStorage.setItem("cuotas",JSON.stringify(data));
            history.push("Cuotas")
            return data;
          });
            break;
      }
    }
    }
    return (
        <>
        <div id="alerta" className={alerta}>
        <p>
          {mensaje}
        </p>
        </div>
        
        <div className="form-profesional">
            <div id="input-tipo" className="input area-iz-aba">
            <label>Tipo</label>
            <Select value={profesional as ValueType<OptionType,false>} onChange={option => listaProfesional(option)} options={options} />
            </div>
            <div id="codigo-profesional" className="input area-iz-arr">
            <label>Código</label>
            <input className={"input-normal "+ borde+""} onChange={event => setCod(event.target.value)} type="text" placeholder="Ingrese el codigo del medico" id="cod-medico"/>
            </div>
            <div id="nombre-profesional" className="input area-der-arr">
            <label>Nombre </label>
            <ScaleLoader loading={loading} height={10} width={3} radius={2} margin={2}/><input className="input-normal" type="text" value={nombre} disabled id="nom-med"/>
            </div>
            
            <div id="tipo-pago" className="input area-der-aba">
            <label>Tipo de Pago</label>
            
            <Select options={pay_options} value={tipoPago as ValueType<OptionType,false>} onChange={option => {listaTipoPago(option); setTipoPago(option)}} />
            </div>
                <button className="botones btn-consultar" id="btn-consultar" onClick={datosMedico}>Consultar</button>
                <button className="botones btn-sig" id="btn-sig" onClick={consultarPagos} disabled={botonActivo}>Siguiente</button>
                
        </div>
        
        </>
    )
}
